/* css for decidim_kids */

#user-minors-table__wrapper {
  .table-scroll {
    @apply overflow-x-auto;

    table {
      border-collapse: unset;

      @apply mb-0 text-gray-2 border border-background-4 w-full;
    }
  }

  .table-list {
    @apply border-collapse border-spacing-0;

    thead tr {
      @apply bg-background-2;

      a {
        @apply font-semibold;

        &:hover {
          @apply underline;
        }
      }
    }

    td {
      @apply border-b border-background-4 text-sm;

      &:not(:last-child) {
        @apply border-r border-background-4;
      }
    }

    th {
      .sort_link.desc,
      .sort_link.asc {
        @apply underline;
      }
    }

    thead th {
      @apply border-b border-background-4 text-xs font-semibold;

      &:not(:last-child) {
        @apply border-r border-background-4;
      }

      &:first-child {
        @apply text-left pl-4;
      }
    }

    tbody tr td {
      @apply p-3;
    }

    thead th,
    thead td,
    tfoot th,
    tfoot td {
      @apply p-2;
    }

    tr:nth-child(even) {
      @apply bg-background-2;
    }

    th:not(:first-child),
    td:not(:first-child) {
      @apply text-center;
    }

    th [type="checkbox"],
    td [type="checkbox"] {
      @apply m-0;
    }

    &.table-list--selectable {
      th:nth-child(2),
      td:nth-child(2) {
        @apply text-left;
      }
    }

    thead tr a,
    thead tr {
      @apply text-gray-2 text-xs;
    }

    td a:not(.button) {
      @apply underline text-md font-semibold text-secondary;
    }

    td > .button {
      @apply mb-0;
    }

    .table-list__date,
    .table-list__state {
      @apply text-sm;
    }

    .tabel-list__left {
      @apply text-left;
    }

    .icon-active {
      @apply bg-[#5dc560] inline-block w-3 h-3 align-middle mr-2 rounded-[50%];
    }
  }

  .table-list__actions {
    @apply text-center whitespace-nowrap;

    a:not(:last-child) {
      @apply pr-2;
    }

    &:last-child {
      @apply text-right;
    }

    span {
      @apply mr-1;
    }

    svg {
      @apply inline-block fill-secondary w-5 h-5;

      &.action-icon--disabled {
        @apply fill-gray mr-2;
      }
    }

    .action-icon.action-icon--remove {
      svg {
        fill: #e66a5d !important;
      }
    }

    .action-space {
      @apply w-5 h-5;
    }
  }
}
